import  $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,appGetUrlPath} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import Search_Filters from '../component_version2/search_filters.js';

import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';

import './checkout_cat.css';

let appUrtPath;

let loginUser="";
let getUser="";
let clsCheckOutCart="";
let loc="";
let products="";
let categoryParam="";

let finalAmt=0;
let couponDiscount=0;

let uri;
class Checkout_Cart extends React.Component{

    RmElement(index){
        //alert("i m delete "+index);
       let cartItems=localStorage.getItem("_cart");
        if(cartItems!=null && cartItems!==""){
           let getItem=JSON.parse(cartItems);
            var items=[];
            for(var i=0;i<getItem.length;i++){
                //alert(getItem[i].item_name);
                if(i!=index){
                    items.push({"item_name":getItem[i].item_name,"item_code":getItem[i].item_code,
                    "item_rate":getItem[i].item_rate,"item_quantity":getItem[i]["item_quantity"],"amount":getItem[i]["amount"],"company_name":getItem[i].company_name,"item_rf_id":getItem[i].item_rf_id,"img_c":getItem[i].img_c});
                }
             
              }
              localStorage.setItem("_cart",JSON.stringify(items));
              cartItems=localStorage.getItem("_cart");
              cartItems=JSON.parse(cartItems);
              //alert(cartItems.length);
             this.setState({cartItems:cartItems});
           
        }
      }

    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        categoryParam= this.props.match.params.name;
        appUrtPath=appGetUrlPath();
        uri="/login?rf=5cCsC5JlrxcTKe";
        getUser= getUserObjData();
        if(getUser!==""){
       
            uri="/steptwo?rf="+getUser[0].id;
          }
       

        /* cart element listing */
        let getItem= localStorage.getItem("_cart");
     var items=[];
     if(getItem!=null && getItem!==""){
        getItem=JSON.parse(localStorage.getItem("_cart"));
     }else{
        getItem=[];
     }
     this.state={cartItems:getItem,totalAmount:0,shipping:0,billAmount:0};

      }
    componentDidMount(){
        clsCheckOutCart=this;
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
        RespBkMthdMapp(clsCheckOutCart,"chkPlusMinus",function(data){
            let getItem=JSON.parse(localStorage.getItem("_cart"));
            clsCheckOutCart.setState({cartItems:getItem});
        })
        }
        
        setTotalBillmounts(tot_amount,shipping,bill_amount){
            this.setState({totalAmount:tot_amount,shipping:shipping,billAmount:bill_amount});
        }
       
    
    
    render(){
        return(<div>
             <Front_Header/>
                {/* div  */}
              <div id="pgComp">
            <div className="cart-main-area pt-100px pb-100px">
                <div className="container">
                <h3 class="cart-page-title">Your cart items</h3>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="table-content table-responsive cart-table-content">
                            <table>
                            <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product Name</th>
                                        <th>Unti Price</th>
                                        <th>Qty</th>
                                        <th>Subtotal</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cartItems.map((item,index)=>{
                                        let proUrl= encodeURI("../product/"+item.item_name+"?rf="+item.enc_tmp_id);
                                        if(index==0){
                                            finalAmt=0;
                                        }
                                                if(item.item_name==="coupon discount"){
                                                    couponDiscount=item.item_rate;
                                                   
                                                }else{
                                                    finalAmt+=parseFloat(item.amount);
                                                }
                                             

                                    return <tr>
                                    <td class="product-thumbnail">
                                        <a href={proUrl}><img class="mini-img-responsive ml-15px" src={appUrtPath+item.img_c} alt={item.item_name}/></a>
                                    </td>
                                    <td class="product-name"><a href={proUrl}>{item.item_name}</a></td>
                                    <td class="product-price-cart"><span class="amount">₹{item.item_rate}</span></td>
                                    <td class="product-quantity">
                                        <div rfKey={item.item_rf_id} class="cart-plus-minus_my">
                                            <div class="dec qtybutton_my">-</div>
                                            <input class="cart-plus-minus-box" type="text"  name="qtybutton" value={item.item_quantity}/>
                                        <div class="inc qtybutton_my">+</div></div>
                                    </td>
                                    <td class="product-subtotal">₹{item.amount}</td>
                                    <td class="product-remove">
                                        
                                        <a onClick={()=>this.RmElement(index)} href="javascript:void(0)"><i class="icon-close"></i></a>
                                    </td>
                                </tr>

                                    })}
                                    
                                    
                                </tbody>
                                </table>
                            </div>

                    </div>
                    </div>
              <div className="row">
                 <div className="col-md-4"></div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div class="grand-totall">
                                <div class="title-wrap">
                                    <h4 class="cart-bottom-title section-bg-gary-cart">Cart Total</h4>
                                </div>
                                <h5 className="black">Products Cost <span>₹{finalAmt}</span></h5>
                                <div class="total-shipping">
                                    <h5 className="black">Estimated Tax<span>TBD</span></h5>
                                </div>
                                <div class="total-shipping">
                                    <h5 className="black">Estimated shipping<span>TBD</span></h5>
                                </div>
                                <h4 class="grand-totall-title">Grand Total <span>₹{finalAmt}</span></h4>
                                <a href={uri}>Proceed to Checkout</a>
                            </div>
                </div>    
            </div>       
                    </div>
            </div>
            
              
           </div>
     <Footer/>
        </div>);
    }
}
export default Checkout_Cart; 