import React from 'react';
import './comp_sorting_bar.css';
export const onChangeEvent=()=>{
    //alert("i m in onCHange");
}
const Comp_Sorting_Bar=(props)=>{
    let countMsg="";
        if(props.count!=="undefined"){
            if(props.count==0){
                //ountMsg="No Products Found";
            }else if(props.count==1){
                countMsg=props.count +" Product Found";
            }else{
                countMsg=props.count+" Products Found";
            }
        }
    return (
        
      

        <div class="parent_tb">
        <div class="line_tb d-flex">
            <div id="l_pro_counts" class="left_tb col fl-left px-2">
                {countMsg}
            </div>
            <div class="right_tb col fl-right">
                <select class="form-control onchangeselect" style={{"max-width":"200px"}}>
                    <option value="default">-Sort By-</option>
                    {/* <option value="Price Low to High">Price Low to High</option>
                    <option value="Price High to Low">Price High to Low</option>
                    <option value="Brand">Brand</option> */}
                    <option value="Name A – Z">Name A – Z</option>
                    <option value="Name Z – A">Name Z – A</option>
                </select>
            </div>
        </div>
    </div>
)
}
export default Comp_Sorting_Bar;